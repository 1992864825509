<template>
<transition name="fade">
  <!--begin::Wrapper-->
  <div
    class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
    style="background-color: #ffffff !important;"
    v-show="state.displayLogin"
  >
    <!--begin::Form-->
    <Form
      class="form w-100"
      id="formLogin"
      ref="formLogin"
      @submit="onSubmitLogin"
      :validation-schema="login"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <img alt="Logo" src="media/logos/logo-StillOnline-couleur.svg" class="h-95px" />
        <!--end::Title-->
      </div>
      <!--begin::Heading-->
      <!--
      <div class="mb-10 bg-light-info p-8 rounded">
        <div class="text-info">
          Mode Démo, utiliser le login : <strong>demo@demo.com</strong> et le
          mot de passe <strong>alicia</strong> pour continuer.
        </div>
      </div>
      -->
      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark">{{$t("Identifiant")}}</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="username"
          v-model="state.username"
          autocomplete="off"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="username" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack mb-2">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6 mb-0"
            >{{$t("Mot de passe")}}</label
          >
          <!--end::Label-->

          <!--begin::Link-->
          <router-link to="/password-reset" class="link-primary fs-6 fw-bolder">
            {{$t("Mot de passe oublié ?")}}
          </router-link>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          name="password"
          autocomplete="off"
          v-model="state.password"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label"> {{$t("Se connecter")}} </span>

          <span class="indicator-progress">
            {{$t("Chargement...")}}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      username: "",
      password: "",
      displayLogin : false,
    });
    const { t } = useI18n()

    //let username = "";
    //let password = "";

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email(t("L'identifiant doit être un email valide"))
        .required(t("L'identifiant est obligatoire"))
        .label("username"),
      password: Yup.string()
        .required(t("Le mot de passe est obligatoire"))
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            mAxiosApi.prototype.getAxios("/logLogin")
            if (router.currentRoute.value.params.loginpassword) {
              if (router.currentRoute.value.params.redirect) {
                const stringRec = (router.currentRoute.value.params.redirect) as string;
                router.push({ name: stringRec });
              } else {
                router.push({ name: "stillboard" });  //stillboard
              }
              return true;
            }
            Swal.fire({
              title: t("Connexion établie !"),
              text:
                t("Bienvenue")+ " " +
                store.getters.currentUser.con_prenom +
                " " +
                store.getters.currentUser.con_nom +
                " " + t("sur votre board StillOnline !"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("Accèder à StillOnline"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              
              if (router.currentRoute.value.params.redirect) {
                const stringRec = (router.currentRoute.value.params.redirect) as string;
                router.push({ name: stringRec });
              } else {
                if (store.getters.currentUser.con_redirect_auto) {
                  router.push({ name: store.getters.currentUser.con_redirect_auto });  //stillboard 
                }else {
                  router.push({ name: "stillboard" });  //stillboard 
                }
                

              }

            });


          })
          .catch(() => {
            Swal.fire({
              text: t("Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion."),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("Retourner sur la page de connexion"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);
    };



    onMounted(() => {
      localStorage.removeItem('storedConst');
      if (store.getters.isUserAuthenticated){
        if (router.currentRoute.value.params.redirect) {
          const stringRec = (router.currentRoute.value.params.redirect) as string;
          router.push({ name: stringRec });
          return false;
        } 
      }

      state.displayLogin = true;

      if (router.currentRoute.value.params.loginpassword) {
        store.dispatch(Actions.LOGOUT);
        const stringRec = (router.currentRoute.value.params.loginpassword) as string;
        state.username = window.atob(stringRec).split("|$|")[0];
        state.password = window.atob(stringRec).split("|$|")[1];
        
        if (submitButton.value) submitButton.value.click();
      }
    });      

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      formLogin,
    };
  },
});
</script>
